import React, { useContext } from 'react'
import Button from '@material-ui/core/Button'
import nostores from '../../../../assets/images/no_store_near_you.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { CurrentSelectedAddress } from '../../../globalstates'

import 'react-lazy-load-image-component/src/effects/blur.css';

const NoStoreFound = () => {
  const [currentSelectedAddress, setcurrentSelectedAddress] = useContext(CurrentSelectedAddress)

  const handleAddressChange = () => {
    setcurrentSelectedAddress({
      address: 'Manila, PH',
      lat: 14.599512,
      lng: 120.984222,
      refreshLists: true,
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'visby',
        marginTop: '5em'
      }}
    >
      <div
        style={{
          height: '200px',
          width: '300px',
          textAlign: 'center',
        }}
      >
        <LazyLoadImage
          useIntersectionObserver={true}
          src={nostores}
          alt="No Stores near you"
          effect="blur"
          width="190px"
        />

        <h1>Sorry</h1>
        <p>No shops currently available near you</p>
        <p>
          Sparkle is currently servicing City of Manila, Please contact us,
          we'll do our best to get the best kabayanijuan products out to you!
          #Bilhinsarilingatin
        </p>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleAddressChange}
        >
          View Manila Products
        </Button>
        <br />
        <br />
        <br />
        <Button
          color="tertiary"
          variant="contained"
          onClick={() => window.open('http://sparkles.com.ph', '_blank')}
        >
          I want sparkle here
          <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "5px" }} />
        </Button>
      </div>
    </div>
  )
}

export default NoStoreFound
