import React, { useState, useEffect } from "react"
import StaggeredCard from "../component/StaggeredCard"
import NoStoreFound from "../component/NoStoreFound"
import useWindowDimensions from "../../../../custom-hooks/useWindowDimensions"

import { withDeferRender } from 'react-defer-renderer'
import { FixedSizeList as List } from "react-window"

import sortArrayByNumberOfElements from "../../../../helpers/sortArrayByNumberOfElements"

// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import 'firebase/analytics';

// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useCollectionData } from 'react-firebase-hooks/firestore';

// const firestore = firebase.firestore();

let testShops = []

const Row = ({ index, style }) => {
  console.log(`this is row ${index}`)
  let shops = testShops[index]

  return <div style={style} >
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '0.5em',
      paddingRight: '0.5em'
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        {shops[0] && <StaggeredCard shop={shops[0]} Size={"Large"} />}
        {shops[2] && <StaggeredCard shop={shops[2]} Size={"Small"} />}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
        {shops[1] && <StaggeredCard shop={shops[1]} Size={"Small"} />}
        {shops[3] && <StaggeredCard shop={shops[3]} Size={"Large"} />}
      </div>
    </div>
  </div >
}


const ShopsList = ({ shops = [], to = "/shop" }) => {
  console.log('rehndering shops list')
  // const [surge, setSurge] = useState({})

  // useEffect(async () => {
  //   const surgeRef = firestore.doc(`SURGE/ZZZAoCXyyekSyIc2zM0P`);
  //   const surgeSnap = await surgeRef.get()
  //
  //   setSurge(surgeSnap.data());
  // }, [])

  //const [rowShops, setRowShops] = useState([])

  let isShopAnArray = Array.isArray(shops)

  if (!isShopAnArray) {
    return <NoStoreFound />
  }

  if (shops.length <= 0) {
    return <NoStoreFound />
  }


  let filteredShops = shops.filter(function (s) {
    if (
      s.name === "Sparkle Shop" ||
      s.name === "Food Basket Corporation's Shop" ||
      s._id === "6311979f7cc0c70016c8ebe1"
    ) {
    } else {
      return s
    }
  })


  let rowShops = sortArrayByNumberOfElements(filteredShops, 4)
  testShops = rowShops
  // remove from array Sparkle shop and FBC



  return (
    <div style={{ paddingTop: "0.4em" }}>
      {rowShops.length > 0 ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginBottom: '5em'
        }}>
          < List style={{ marginLeft: 'auto', marginRight: 'auto' }}
            height={window.innerHeight} itemCount={rowShops.length} itemSize={576} width={425}>
            {Row}
          </List>
        </div>
      ) : (
        <NoStoreFound />
      )}
    </div>
  )
}

export default withDeferRender(ShopsList)
